import { LinkUpArrowIcon } from '../../icons';
import { useGlobalStore } from '../../store';
import { getExplorerUrl } from '../../utils';

const CryptoConfirmationPanel = () => {
  const cryptoTopupResponse = useGlobalStore(
    (state) => state.cryptoTopupResponse,
  );
  const clearAllPaymentState = useGlobalStore(
    (state) => state.clearAllPaymentState,
  );

  const paymentTxUrl = cryptoTopupResponse
    ? getExplorerUrl(cryptoTopupResponse.id, cryptoTopupResponse.token)
    : undefined;

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Purchase Complete</div>
      </div>

      <div className="text-sm">
        Payment has been sent. Your account will be credited shortly.
      </div>

      <div>
        <div className="mt-8 w-full text-sm">
          View payment transaction below:
        </div>
        <a
          className="mt-4 flex text-sm"
          href={paymentTxUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="View Payment Transaction"
        >
          {cryptoTopupResponse?.id}
          <LinkUpArrowIcon />
        </a>
      </div>

      {cryptoTopupResponse?.token === 'arweave' && (
        <div className="mt-8 w-full rounded border border-l-[.3125rem] border-default p-4 text-sm text-fg-disabled">
          Note: Transaction may take a few minutes to be confirmed before
          showing up on Viewblock.
        </div>
      )}

      <div className="mt-8 flex w-full justify-end border-t border-default pt-8">
        <div className="grow"></div>
        <button
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={clearAllPaymentState}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default CryptoConfirmationPanel;
