import { TurboWincForFiatResponse, USD } from '@ardrive/turbo-sdk/web';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { isEmail } from 'validator';
import { valueStringError } from '../../constants';
import useAddress from '../../hooks/useAddress';
import useCountries from '../../hooks/useCountries';
import { CircleXIcon, RefreshIcon } from '../../icons';
import { getPaymentIntent } from '../../paymentService';
import { useGlobalStore } from '../../store';
import { getWincForFiat, wincToCredits } from '../../utils';

const FormEntry: FC<{
  name: string;
  label: string;
  children: ReactNode;
  errorText?: string;
}> = ({ name, label, children, errorText }) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm text-fg-disabled" htmlFor={name}>
        {label}
      </label>
      <div className="w-full rounded border border-default">{children}</div>
      {errorText && <div className="text-xs text-error">{errorText}</div>}
    </div>
  );
};

const isValidPromoCode = async (
  paymentAmount: number,
  promoCode: string,
  destinationAddress: string,
) => {
  try {
    const response = await getWincForFiat({
      amount: USD(paymentAmount / 100),
      promoCode,
      destinationAddress,
    });
    return response.adjustments.length > 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e: unknown) {
    return false;
  }
};

const PaymentPanel = () => {
  const countries = useCountries();
  const address = useAddress();

  const paymentAmount = useGlobalStore((state) => state.paymentAmount);
  const setPaymentAmount = useGlobalStore((state) => state.setPaymentAmount);
  const paymentIntent = useGlobalStore((state) => state.paymentIntent);
  const setPaymentIntent = useGlobalStore((state) => state.setPaymentIntent);
  const setPaymentInformation = useGlobalStore(
    (state) => state.setPaymentInformation,
  );

  const promoCode = useGlobalStore((state) => state.promoCode);
  const setPromoCode = useGlobalStore((state) => state.setPromoCode);
  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const [localPromoCode, setLocalPromoCode] = useState<string>();
  const [promoCodeError, setPromoCodeError] = useState<string>();

  const stripe = useStripe();
  const elements = useElements();

  const [estimatedCredits, setEstimatedCredits] =
    useState<TurboWincForFiatResponse>();

  const [name, setName] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [keepMeUpdated, setKeepMeUpdated] = useState<boolean>(false);

  const [nameError, setNameError] = useState<string>();
  const [cardError, setCardError] = useState<string>();
  const [countryError, setCountryError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();

  const [countdown, setCountdown] = useState<number>(5 * 60);

  const [paymentMethodError, setPaymentMethodError] = useState<string>();

  const formatCountdown = (countdown: number) => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const updateEstimatedCredits = useCallback(async () => {
    if (paymentAmount && turboUnauthenticatedClient && address) {
      try {
        const response = await getWincForFiat({
          amount: USD(paymentAmount / 100),
          promoCode: promoCode,
          destinationAddress: address.address,
        });
        setEstimatedCredits(response);
      } catch (e: unknown) {
        console.error(e);
        setEstimatedCredits(undefined);
      }
    }
  }, [address, paymentAmount, promoCode, turboUnauthenticatedClient]);

  useEffect(() => {
    updateEstimatedCredits();
  }, [paymentIntent, updateEstimatedCredits]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let c = countdown - 1;
      if (c < 0) {
        c = 5 * 60;
        updateEstimatedCredits();
      }
      setCountdown(c);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  });

  const isValid =
    name &&
    estimatedCredits &&
    !cardError &&
    country &&
    (!email || isEmail(email));

  const cardElementOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: 'white',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
    },
    hidePostalCode: true,
  };

  const actualPaymentAmount = estimatedCredits
    ? (estimatedCredits.actualPaymentAmount / 100).toFixed(2)
    : 0;

  const adjustment =
    estimatedCredits?.adjustments && estimatedCredits.adjustments.length > 0
      ? estimatedCredits.adjustments[0]
      : undefined;

  const discountAmount = adjustment
    ? `(${100 - adjustment.operatorMagnitude * 100}% discount applied)`
    : undefined;

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Payment Details</div>
        <div className="mt-4 text-sm font-semibold text-fg-disabled">
          We do not save credit card information. See our T&C for more info.
        </div>
      </div>

      <div className="mt-8 flex w-full flex-col border-y border-default pb-20 pt-6">
        <div className="grid grid-cols-2">
          {estimatedCredits ? (
            <div className="flex flex-col ">
              <div className="text-2xl font-bold">
                {wincToCredits(Number(estimatedCredits?.winc ?? 0)).toFixed(4)}{' '}
                Credits
              </div>
              <div className="text-sm">
                ${actualPaymentAmount}{' '}
                {discountAmount && (
                  <span className="text-fg-disabled">{discountAmount}</span>
                )}
              </div>
            </div>
          ) : (
            <div className="text-base font-bold text-error">
              {valueStringError}
            </div>
          )}
          <div className="flex flex-col items-center bg-surface px-10 py-2.5 text-center text-sm  text-fg-disabled">
            <div>
              Quote Updates in{' '}
              <span className="text-fg-muted">
                {formatCountdown(countdown)}
              </span>
            </div>
            <button
              className="flex items-center gap-1"
              onClick={() => {
                setCountdown(5 * 60);
                updateEstimatedCredits();
              }}
            >
              <RefreshIcon /> Refresh
            </button>
          </div>
        </div>

        <form className="mt-8 flex  flex-col gap-6">
          <FormEntry name="name" label="Name on Card *" errorText={nameError}>
            <input
              className="size-full bg-canvas px-4 py-2"
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                const v = e.target.value ?? '';
                const cleaned = v.replace(/[^a-zA-Z\s]/g, '');
                setName(cleaned);
                setNameError(
                  cleaned.length == 0 ? 'Name is required' : undefined,
                );
              }}
            ></input>
          </FormEntry>
          <FormEntry name="name" label="Credit Card *" errorText={cardError}>
            <CardElement
              options={cardElementOptions}
              className="w-full bg-canvas px-4 py-2 text-white"
              onChange={(e) => {
                setCardError(e.error?.message);
              }}
            />
          </FormEntry>
          <FormEntry name="name" label="Country *" errorText={countryError}>
            <select
              className="mr-2 w-full bg-canvas px-4 py-2 text-sm text-fg-muted"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
                setCountryError(
                  !e.target.value ? 'Country is required' : undefined,
                );
              }}
            >
              <option value="">Select Country</option>
              {countries?.data?.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </FormEntry>
          {promoCode ? (
            <div className="flex flex-col gap-1">
              <label className="text-sm text-fg-disabled">Promo Code</label>
              <div className="flex flex-row items-center gap-1 text-sm text-fg-disabled text-green-500">
                Promo code successfully applied.
                <button
                  className="text-white"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (address && paymentAmount) {
                      try {
                        // reset payment intent to one without promo code
                        const newPaymentIntent = await getPaymentIntent(
                          address.address,
                          paymentAmount,
                          address.token,
                        );
                        setPaymentIntent(newPaymentIntent.paymentSession);
                        setPromoCode(undefined);
                        setLocalPromoCode(undefined);
                        setPromoCodeError(undefined);
                      } catch (e: unknown) {
                        console.error(e);
                        setPromoCodeError(
                          'Error removing promo code, please try again.',
                        );
                      }
                    }
                  }}
                >
                  <CircleXIcon className="size-5 text-green-500" />
                </button>
              </div>
              {promoCodeError && (
                <div className="text-xs text-error">{promoCodeError}</div>
              )}
            </div>
          ) : (
            <FormEntry
              name="name"
              label="Promo Code"
              errorText={promoCodeError}
            >
              <div className="relative">
                <input
                  className="peer size-full bg-canvas px-4 py-2"
                  type="text"
                  name="promoCode"
                  value={localPromoCode || ''}
                  onChange={(e) => {
                    const v = e.target.value ?? '';
                    const cleaned = v.replace(/[^a-zA-Z0-9\s]/g, '');
                    setLocalPromoCode(cleaned);
                    setPromoCodeError(undefined);
                  }}
                ></input>
                <button
                  className="absolute right-2 top-1/2 -translate-y-1/2 px-3 py-1 text-xs text-white opacity-0 transition-opacity focus:opacity-100 peer-focus:opacity-100"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                      paymentAmount &&
                      address &&
                      localPromoCode &&
                      localPromoCode.length > 0
                    ) {
                      if (
                        await isValidPromoCode(
                          paymentAmount,
                          localPromoCode,
                          address.address,
                        )
                      ) {
                        try {
                          const newPaymentIntent = await getPaymentIntent(
                            address.address,
                            paymentAmount,
                            address.token,
                            localPromoCode,
                          );
                          setPaymentIntent(newPaymentIntent.paymentSession);
                          setPromoCode(localPromoCode);
                        } catch (e: unknown) {
                          console.error(e);
                          setPromoCodeError(
                            'Error applying promo code, please try again.',
                          );
                        }
                      } else {
                        setLocalPromoCode(undefined);
                        setPromoCodeError('Promo code is invalid or expired.');
                      }
                    }
                  }}
                >
                  Apply
                </button>
              </div>
            </FormEntry>
          )}

          <div className="mt-4 pt-4">
            <FormEntry
              name="email"
              label="Please leave an email if you want a receipt."
              errorText={emailError}
            >
              <input
                type="text"
                className="w-full bg-canvas px-4 py-2"
                name="email"
                value={email}
                onChange={(e) => {
                  const newEmail = e.target.value;
                  setEmail(newEmail);
                  setEmailError(
                    newEmail.length == 0 || isEmail(e.target.value)
                      ? undefined
                      : 'Please enter a valid email address.',
                  );
                }}
              ></input>
            </FormEntry>
            {/* <div className="text-sm text-fg-disabled">
              Please leave an email if you want a receipt.
            </div> */}
            <div className="mb-8 mt-6 flex items-center">
              <input
                disabled={!email}
                type="checkbox"
                className="mr-2"
                id="keepMeUpdatedCheckbox"
                checked={keepMeUpdated}
                onChange={(e) => setKeepMeUpdated(e.target.checked)}
              ></input>
              <label
                className="text-sm text-fg-disabled"
                htmlFor="keepMeUpdatedCheckbox"
              >
                Keep me up to date on news and promotions.
              </label>
            </div>
          </div>
        </form>
      </div>

      <div className="mt-8 flex w-full justify-end">
        <button
          className="text-sm text-fg-disabled"
          onClick={() => {
            setPromoCode(undefined);
            setPaymentAmount(undefined);
            setPaymentIntent(undefined);
          }}
        >
          Back
        </button>
        <div className="grow"></div>
        <button
          disabled={!isValid}
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={async () => {
            const cardElement = elements?.getElement(CardElement);

            if (name && country && cardElement && stripe) {
              const { paymentMethod, error } = await stripe.createPaymentMethod(
                {
                  type: 'card',
                  card: cardElement,
                  billing_details: {
                    name,
                    email: keepMeUpdated ? email : undefined,
                  },
                },
              );

              if (error) {
                console.error(error);
                setPaymentMethodError(error.message);
              } else if (paymentMethod) {
                setPaymentInformation({
                  paymentMethodId: paymentMethod.id,
                  email,
                });
              }
            }
          }}
        >
          Next
        </button>
      </div>

      {paymentMethodError && (
        <div className="mt-2 w-full text-right text-sm text-error">
          {paymentMethodError}
        </div>
      )}
    </div>
  );
};

export default PaymentPanel;
