import { useState } from 'react';
import CopyButton from '../../components/CopyButton';
import {
  currencyLabels,
  errorSubmittingTransactionToTurbo,
  TURBO_WALLETS_URL,
} from '../../constants';
import useAddress, { TransferTransactionResult } from '../../hooks/useAddress';
import useTurboWallets from '../../hooks/useTurboWallets';
import BlockingMessageModal from '../../modals/BlockingMessageModal';
import { useGlobalStore } from '../../store';
import { formatWalletAddress } from '../../utils';

const StepDot = ({ active }: { active?: boolean }) => {
  return (
    <div
      className={`z-10 size-4 rounded-full ${active ? 'bg-[#f00]' : 'bg-fg-disabled'}`}
    ></div>
  );
};

const CryptoConfirmationPanel = () => {
  const address = useAddress();
  const cryptoTopupValue = useGlobalStore((state) => state.cryptoTopupValue);
  const clearAllPaymentState = useGlobalStore(
    (state) => state.clearAllPaymentState,
  );

  const { data: turboWallets } = useTurboWallets();

  const [transferTransactionResult, setTransferTransactionResult] =
    useState<TransferTransactionResult>();

  const turboWallet =
    address && turboWallets ? turboWallets[address.token] : undefined;

  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );

  const [paymentError, setPaymentError] = useState<string>();

  const [signingMessage, setSigningMessage] = useState<string>();
  const [transactionSubmitted, setTransactionSubmitted] =
    useState<boolean>(false);

  const submitNativeTransaction = async (amount: number) => {
    setPaymentError(undefined);
    if (address?.submitNativeTransaction && turboWallet) {
      try {
        setSigningMessage(
          'Signing transaction with your wallet and awaiting confirmation...',
        );
        const response = await address.submitNativeTransaction(
          amount,
          turboWallet,
        );
        setTransferTransactionResult(response);
      } catch (e: unknown) {
        console.error(e);
        setPaymentError(errorSubmittingTransactionToTurbo);
      } finally {
        setSigningMessage(undefined);
      }
    }
  };

  const submitTransactionToTurbo = async () => {
    setPaymentError(undefined);
    if (turboUnauthenticatedClient && transferTransactionResult) {
      setSigningMessage('Submitting Transaction to Turbo...');
      try {
        const response = await turboUnauthenticatedClient.submitFundTransaction(
          {
            txId: transferTransactionResult.txid,
          },
        );

        if (response.status === 'failed') {
          setPaymentError(errorSubmittingTransactionToTurbo);
        } else {
          setTransactionSubmitted(true);
        }
      } catch (e: unknown) {
        console.error(e);
        setPaymentError(errorSubmittingTransactionToTurbo);
      } finally {
        setSigningMessage(undefined);
      }
    }
  };

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Submit Transactions</div>
      </div>

      <div className="mt-8 flex w-full gap-2">
        <div className="text-sm text-fg-muted">Amount:</div>
        <div className="text-sm">
          {cryptoTopupValue} {address ? currencyLabels[address.token] : 'TOKEN'}
        </div>
      </div>

      <div className="relative mt-8 w-full">
        <div className="absolute left-[.475rem] top-4 h-5 border-l border-fg-disabled/50"></div>
        <div className="flex w-full items-center gap-2 text-sm">
          <StepDot active={!transferTransactionResult} />
          <div className="grow text-sm text-fg-muted">
            Step 1: Transfer{' '}
            {address ? currencyLabels[address?.token] : 'TOKEN'} to Turbo
          </div>
          <div className="ml-2 text-sm">
            <button
              disabled={!!transferTransactionResult}
              className="rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (address?.submitNativeTransaction && cryptoTopupValue) {
                  submitNativeTransaction(cryptoTopupValue);
                }
              }}
            >
              {transferTransactionResult ? 'Completed' : 'Send'}
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="ml-[.475rem] border-l border-fg-disabled/50"></div>
        {!transferTransactionResult && (
          <div className="ml-6 mt-2 p-4 text-sm">
            This step sends {address ? currencyLabels[address?.token] : 'TOKEN'}{' '}
            to Turbo. You can verify the recipient is Turbo's wallet address{' '}
            <a
              href={TURBO_WALLETS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-fg-muted underline"
            >
              here
            </a>
            .
          </div>
        )}

        {transferTransactionResult && (
          <div className="ml-6 mt-4 rounded bg-surface p-4 text-sm text-fg-disabled">
            <p className="text-fg-muted">Transaction success.</p>
            <div className="mt-4 flex gap-2">
              Transaction ID:{' '}
              <a
                href={transferTransactionResult.explorerURL}
                className="text-fg-muted underline"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="View Payment Transaction"
              >
                {formatWalletAddress(transferTransactionResult.txid, 8)}
              </a>
              <CopyButton textToCopy={transferTransactionResult.txid} />
            </div>
            <p className="mt-4">
              Please record the transaction ID for your records. If there are
              any issues, you can submit the transaction ID to
              <a
                className="ml-1 underline"
                href="mailto:support@ardrive.io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email ArDrive Support"
              >
                customer support
              </a>
            </p>
          </div>
        )}
      </div>
      <div className="relative w-full">
        <div className="absolute left-[.475rem] h-12 border-l border-fg-disabled/50"></div>
        <div className="mt-8 flex w-full items-center gap-2 text-sm">
          <StepDot
            active={
              transferTransactionResult !== undefined && !transactionSubmitted
            }
          />
          <div className="grow text-sm text-fg-muted">
            Step 2: Submit Transaction to Turbo
          </div>
          <div className="ml-2 text-sm">
            <button
              disabled={!transferTransactionResult || transactionSubmitted}
              className="rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                submitTransactionToTurbo();
              }}
            >
              {transactionSubmitted ? 'Completed' : 'Send'}
            </button>
          </div>
        </div>
      </div>
      {!transactionSubmitted && (
        <div className="ml-6 mt-2 p-4 text-sm">
          This step submits the transaction to Turbo. Once the transaction is
          submitted, your account will be credited.
        </div>
      )}
      {paymentError && (
        <div className="ml-6 mt-2 w-full text-right text-sm text-error">
          {paymentError}
        </div>
      )}
      {transactionSubmitted && (
        <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
          <div>
            <div className="font-bold text-fg-muted">Purchase Complete</div>
          </div>

          <div className="text-sm">
            Payment has been sent. Your account will be credited shortly.
          </div>
        </div>
      )}
      <div className="flex w-full justify-end border-t border-default pt-8">
        <div className="grow"></div>
        <button
          disabled={!transactionSubmitted}
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={() => clearAllPaymentState()}
        >
          Complete
        </button>
      </div>
      {signingMessage && (
        <BlockingMessageModal
          onClose={() => {
            setSigningMessage(undefined);
          }}
          message={signingMessage}
        />
      )}
    </div>
  );
};

export default CryptoConfirmationPanel;
