import { TurboSubmitFundTxResponse } from '@ardrive/turbo-sdk/web';
import { useState } from 'react';
import {
  currencyLabels,
  errorSubmittingTransactionToTurbo,
} from '../../constants';
import useAddress from '../../hooks/useAddress';
import BlockingMessageModal from '../../modals/BlockingMessageModal';
import { useGlobalStore } from '../../store';

const ResumeCryptoTopup = () => {
  const address = useAddress();

  const turboUnauthenticatedClient = useGlobalStore(
    (state) => state.turboUnauthenticatedClient,
  );
  const setShowResumeTransactionPanel = useGlobalStore(
    (state) => state.setShowResumeTransactionPanel,
  );

  const [paymentError, setPaymentError] = useState<string>();

  const [signingMessage, setSigningMessage] = useState<string>();

  const [transactionID, setTransactionID] = useState<string>('');

  const [response, setResponse] = useState<TurboSubmitFundTxResponse>();

  const submitTransactionToTurbo = async () => {
    setPaymentError(undefined);
    if (turboUnauthenticatedClient) {
      setSigningMessage('Submitting Transaction to Turbo...');
      try {
        const response = await turboUnauthenticatedClient.submitFundTransaction(
          {
            txId: transactionID,
          },
        );
        if (response.status === 'failed') {
          setPaymentError(errorSubmittingTransactionToTurbo);
        } else {
          setResponse(response);
        }
      } catch (e: unknown) {
        console.error(e);
        setPaymentError(errorSubmittingTransactionToTurbo);
      } finally {
        setSigningMessage(undefined);
      }
    }
  };

  const isComplete =
    response?.status === 'confirmed' || response?.status === 'pending';

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">
          Resume {address ? currencyLabels[address.token] : 'Token'} Topup
        </div>
      </div>

      {response && isComplete ? (
        <div className="mt-8 w-full flex-col items-center gap-2 text-sm">
          Transaction submitted to Turbo. Your account will be credited shortly.
        </div>
      ) : (
        <div className="mt-8 w-full flex-col items-center gap-2 text-sm">
          <div className="grow text-sm text-fg-muted">
            Enter {address ? currencyLabels[address?.token] : 'TOKEN'}{' '}
            Transaction ID below and submit to Turbo for credits.
          </div>
          <div className="text-sm">
            <input
              type="text"
              placeholder={`${address ? currencyLabels[address.token] : ''} Transaction ID`}
              className={`mt-4 flex w-full gap-2 rounded border-2 border-default bg-canvas px-4 py-2`}
              value={transactionID || ''}
              onChange={(e) => setTransactionID(e.target.value.trim())}
            ></input>
          </div>
        </div>
      )}

      <div className="mt-8 flex w-full items-center gap-2 text-sm">
        <button
          className="text-sm text-fg-disabled"
          onClick={() => setShowResumeTransactionPanel(false)}
        >
          Back
        </button>
        <div className="grow "> </div>
        {!isComplete && (
          <div className="ml-2 text-sm">
            <button
              disabled={transactionID.length <= 0}
              className="rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                submitTransactionToTurbo();
              }}
            >
              Submit
            </button>
          </div>
        )}
      </div>
      {paymentError && (
        <div className="mt-2 w-full text-right text-sm text-error">
          {paymentError}
        </div>
      )}
      {signingMessage && (
        <BlockingMessageModal
          onClose={() => {
            setSigningMessage(undefined);
          }}
          message={signingMessage}
        />
      )}
    </div>
  );
};

export default ResumeCryptoTopup;
