import { useGlobalStore } from '../../store';

const FiatTopupComplete = () => {
  const clearAllPaymentState = useGlobalStore(
    (state) => state.clearAllPaymentState,
  );

  return (
    <div className="flex size-full flex-col items-start bg-canvas p-12 text-left">
      <div>
        <div className="font-bold text-fg-muted">Purchase Complete</div>
      </div>

      <div className="text-sm">
        Payment has been sent. Your account will be credited shortly.
      </div>

      <div className="mt-8 flex w-full justify-end border-t border-default pt-8">
        <div className="grow"></div>
        <button
          className="w-40 rounded bg-[#f00] p-2 text-fg-on-disabled disabled:bg-accent-disabled disabled:text-fg-on-disabled"
          onClick={clearAllPaymentState}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default FiatTopupComplete;
