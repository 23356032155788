import { TurboFactory } from '@ardrive/turbo-sdk/web';
import { FC, ReactNode, useEffect } from 'react';
import { PAYMENT_SERVICE_FQDN } from '../constants';
import useAddress from '../hooks/useAddress';
import { useGlobalStore } from '../store';

const GlobalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const address = useAddress();

  const setTurboUnauthenticatedClient = useGlobalStore(
    (state) => state.setTurboUnauthenticatedClient,
  );
  const setTurboAuthenticatedClient = useGlobalStore(
    (state) => state.setTurboAuthenticatedClient,
  );

  useEffect(() => {
    if (address) {
      const turboUnautheticatedClient = TurboFactory.unauthenticated({
        paymentServiceConfig: {
          url: `https://${PAYMENT_SERVICE_FQDN}`,
        },
        token: address.token,
      });
      setTurboUnauthenticatedClient(turboUnautheticatedClient);

      if (address.signer) {
        const turboAuthenticatedClient = TurboFactory.authenticated({
          signer: address.signer,
          token: address.token,
          paymentServiceConfig: {
            url: `https://${PAYMENT_SERVICE_FQDN}`,
            token: address.token,
          },
        });
        setTurboAuthenticatedClient(turboAuthenticatedClient);
      }
    }
  }, [address, setTurboAuthenticatedClient, setTurboUnauthenticatedClient]);

  return children;
};

export default GlobalProvider;
