import {
  TurboAuthenticatedClient,
  TurboCryptoFundResponse,
  TurboUnauthenticatedClient,
} from '@ardrive/turbo-sdk/web';
import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import { create } from 'zustand';

export type PaymentInformation = {
  paymentMethodId: string;
  email?: string;
};

export type ArConnectInfo = {
  address: string;
};

export type GlobalState = {
  turboUnauthenticatedClient?: TurboUnauthenticatedClient;
  turboAuthenticatedClient?: TurboAuthenticatedClient;
  arconnectInfo?: ArConnectInfo;

  // Fiat payment flow state
  paymentAmount?: number;
  paymentIntent?: PaymentIntent;
  paymentInformation?: PaymentInformation;
  paymentIntentResult?: PaymentIntentResult;
  promoCode?: string;

  // Crypto payment flow state
  cryptoTopupValue?: number;
  cryptoManualTopup?: boolean;
  cryptoTopupResponse?: TurboCryptoFundResponse;

  showResumeTransactionPanel: boolean;

  setTurboUnauthenticatedClient: (
    turboUnauthenticatedClient: TurboUnauthenticatedClient,
  ) => void;
  setTurboAuthenticatedClient: (
    turboAuthenticatedClient: TurboAuthenticatedClient,
  ) => void;
  setArConnectInfo: (arconnectInfo?: ArConnectInfo) => void;

  setPaymentIntent: (paymentIntent?: PaymentIntent) => void;
  setPaymentAmount: (paymentAmount?: number) => void;
  setPaymentInformation: (paymentInformation?: PaymentInformation) => void;
  setPaymentIntentResult: (paymentIntentResult?: PaymentIntentResult) => void;
  setPromoCode: (promoCode?: string) => void;

  setCryptoTopupValue: (cryptoTopupValue?: number) => void;
  setCryptoManualTopup: (cryptoManualTopup?: boolean) => void;
  setCryptoTopupResponse: (
    cryptoTopupResponse?: TurboCryptoFundResponse,
  ) => void;

  setShowResumeTransactionPanel: (showResumeTransactionPanel: boolean) => void;

  clearAllPaymentState: () => void;
};

export const useGlobalStore = create<GlobalState>((set) => ({
  showResumeTransactionPanel: false,

  setTurboUnauthenticatedClient(
    turboUnauthenticatedClient: TurboUnauthenticatedClient,
  ) {
    set(() => ({ turboUnauthenticatedClient }));
  },
  setTurboAuthenticatedClient(
    turboAuthenticatedClient: TurboAuthenticatedClient,
  ) {
    set(() => ({ turboAuthenticatedClient }));
  },
  setArConnectInfo: (arconnectInfo?: ArConnectInfo) => {
    set(() => ({ arconnectInfo }));
  },

  setPaymentAmount: (paymentAmount?: number) => {
    set(() => ({ paymentAmount }));
  },
  setPaymentIntent: (paymentIntent?: PaymentIntent) =>
    set(() => ({ paymentIntent: paymentIntent })),
  setPaymentInformation: (paymentInformation?: PaymentInformation) => {
    set(() => ({ paymentInformation: paymentInformation }));
  },
  setPaymentIntentResult: (paymentIntentResult?: PaymentIntentResult) => {
    set(() => ({ paymentIntentResult }));
  },
  setPromoCode: (promoCode?: string) => {
    set(() => ({ promoCode }));
  },

  setCryptoTopupValue: (cryptoTopupValue?: number) => {
    set(() => ({ cryptoTopupValue }));
  },
  setCryptoManualTopup: (cryptoManualTopup?: boolean) => {
    set(() => ({ cryptoManualTopup }));
  },
  setCryptoTopupResponse: (cryptoTopupResponse?: TurboCryptoFundResponse) => {
    set(() => ({ cryptoTopupResponse }));
  },

  setShowResumeTransactionPanel: (showResumeTransactionPanel: boolean) => {
    set(() => ({ showResumeTransactionPanel }));
  },

  clearAllPaymentState: () => {
    set(() => ({
      paymentAmount: undefined,
      paymentIntent: undefined,
      paymentInformation: undefined,
      paymentIntentResult: undefined,
      cryptoTopupValue: undefined,
      cryptoManualTopup: undefined,
      cryptoTopupResponse: undefined,
      promoCode: undefined,
    }));
  },
}));
